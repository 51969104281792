<template>
    <div>
        <p>Prezado Gerente.</p>
        <p>Atendendo ao pedido do departamento 4510/072 – ON (Operações de Negócios), solicitamos a documentação para fiscalização do cliente abaixo que pertence a sua agência.</p>
        <p>A JF Assessoria Rural faz parte das empresas parceiras do Bradesco. Vide <b>Crédito Rural e Desconto de NPR - Ano Agrícola 2021/2022. Número 03/197. Item 20.2. Projetos e Avaliações</b>.</p>
        <br/>
        <!--CONTRATO-->
        <table>
            <tbody>
            <tr>
                <td><strong>CÉDULA&nbsp;&nbsp;</strong></td>
                <td><strong>MUTUARIO</strong></td>
            </tr>
            <tr>
                <td>{{fiscalizacao.financiamento.cedula}}</td>
                <td>{{fiscalizacao.financiamento.mutuario}}</td>
            </tr>
            </tbody>
        </table>
        <br/>
        <!--DOCUMENTAÇÃO-->
        <p><strong>Documentação necessária:</strong></p>
        <ul>
            <li>Telefone do cliente;</li>
            <li v-if="bndes">Nota fiscal - de todos os itens comprados (obrigatório em BNDES);</li>
            <li v-if="bndes">Cédula/Contrato.</li>
            <li v-if="incluirNF">Nota fiscal - Em caso de compra de gado;</li>
            <li v-if="incluirNF2">Nota fiscal - De todos os itens comprados;</li>
            <li v-if="incluirGTA">GTA (Guia de Trânsito Animal);</li>
            <li v-if="incluirFichaSanitaria">Ficha sanitária rural (saldo de gado). Obrigatória em custeio manutenção.</li>
        </ul>
        <br/>

        <p>Enviar os documentos solicitados acima até <strong>{{dataLimite | moment('DD [de] MMMM [de] YYYY')}}</strong> para fiscalizacao@jfassessoriarural.com.br.</p>
        <br/>

        <div style="background-color: #ee6060; color: white; padding: 6px 10px 10px; border: 1px solid #c20000; border-radius: 5px; text-align: center; margin: 0 20px">
            <p style="margin: unset"><b>Atenção!</b></p>
            <p style="margin: unset">O não cumprimento da exigência até a data limite deste e-mail ou a ausência de resposta acarretará em <b>fiscalização irregular</b>. Caso tenha ocorrido algum imprevisto pela não apresentação dos documentos, explicar o motivo imediatamente respondendo este e-mail para que comuniquemos a central.</p>
        </div>
        <br/>

        <!--ASSINATURA-->
        <table style="margin-bottom: 20px">
            <tbody>
            <tr>
                <td>
                    <strong>{{fiscalizacao.supervisedBy.nome + ' ' + fiscalizacao.supervisedBy.sobrenome}}</strong>
                    <br>
                    <strong>(66)99687-8442 <img src="https://drive.google.com/uc?export=view&id=17sQbOpYa7LgsGzkxDbe2yLkC4XG0O4Z4" width="14" height="14"></strong> |
                    <strong>(66)3531-4164 <img src="https://drive.google.com/uc?export=view&id=17sQbOpYa7LgsGzkxDbe2yLkC4XG0O4Z4" width="14" height="14"></strong>
                    <br>
                    <strong><a href="mailto:fiscalizacao@jfassessoriarural.com.br" target="_blank">fiscalizacao@jfassessoriarural.com.br</a></strong>
                </td>
            </tr>
            </tbody>
        </table>

        <email-footer1 />
    </div>
</template>

<script>
import EmailFooter1 from "@/views/servico/fiscalizacao/fragments/email_templates/EmailFooter1";
export default {
    name: "EmailSolicitacaoDocumentoTemplate",
    components: {EmailFooter1},
    props:{
        fiscalizacao:null,
        dataLimite:null,
    },
    watch:{
        fiscalizacao: {
            immediate: true,
            handler(){
                this.incluirGTA = false;
                this.incluirFichaSanitaria = false;
                this.incluirNF = false;
                this.incluirNF2 = false;
                this.bndes = false;

                if(this.fiscalizacao.tipo.id === 5){
                    this.bndes = true;
                    return
                }

                if(this.fiscalizacao.tipo.id === 1){
                    this.incluirNF = true;
                    this.incluirFichaSanitaria = true;
                    if(this.solicitarGtaDeEmpreendimento.includes(this.fiscalizacao.financiamento.empreendimento.id)){
                        this.incluirGTA = true;
                    }
                    if(this.solicitarGtaDeFinalidade.includes(this.fiscalizacao.financiamento.finalidade.id)){
                        this.incluirGTA = true;
                    }
                }

                if (this.fiscalizacao.tipo.id === 2){
                    if(this.solicitarNfDeOrigemRecursos.includes(this.fiscalizacao.financiamento.origemRecursos.id)){
                        this.incluirNF = true;
                    }
                }

                if (this.fiscalizacao.tipo.id === 3 || this.fiscalizacao.tipo.id === 4){
                    this.incluirNF2 = true;
                }
            }
        },
    },
    data(){
        return{
            incluirGTA: false,
            incluirFichaSanitaria: false,
            incluirNF: true,
            incluirNF2: true,
            bndes: false,
            solicitarGtaDeEmpreendimento: [1,3,9],
            solicitarGtaDeFinalidade: [3],
            solicitarNfDeOrigemRecursos: [10,12,13,14,15],
        }
    }
}
</script>

<style scoped>

</style>