<template>
    <!--OUTROS SERVIÇOS-->
    <div>
        <hr style="margin: 8px 0">
        <div style="background: rgba(197,219,101,1);border-radius: 5px;background: linear-gradient(0deg, rgba(164,190,53,1) 0%, rgba(229,247,147,1) 100%);  padding: 20px 20px 4px 0px;">
            <p style="font-size: 22px; margin: unset; color: #0d4200; padding-left: 20px"><b>OUTROS SERVIÇOS PRESTADOS:</b></p>
            <ul style="color: #0d4200; margin: 16px 0; padding-left: 40px">
                <li style="margin-bottom: 6px">PROJETOS AGRÍCOLAS E PECUÁRIOS (BIOMA AMAZÔNIA / BNDES)</li>
                <li style="margin-bottom: 6px">ELABORAÇÃO DE COORDENADAS GEODÉSICAS</li>
                <li style="margin-bottom: 6px">FISCALIZAÇÃO DE IMÓVEIS RURAIS</li>
                <li style="margin-bottom: 6px">FISCALIZAÇÕES</li>
                <li style="margin-bottom: 6px">VISTORIA PRÉVIA</li>
            </ul>
        </div>

        <table style="text-align: center; font-size: 12px; width: 100%; margin-top: 20px">
            <tr>
                <td style="width: 25%; border-right: 1px solid #d4d4d4">
                    <p style="margin: unset"><b>ANANIAS S. C. NETO</b></p>
                    <p style="margin: unset">(66) 99935-7239</p>
                </td>
                <td style="width: 25%; border-right: 1px solid #d4d4d4">
                    <p style="margin: unset"><b>CAMILA M. DA SILVA</b></p>
                    <p style="margin: unset">(66) 99642-4782</p>
                </td>
                <td style="width: 25%; border-right: 1px solid #d4d4d4">
                    <p style="margin: unset"><b>DANILO O. LIMA</b></p>
                    <p style="margin: unset">(66) 99673-6372</p>
                </td>
                <td style="width: 25%">
                    <p style="margin: unset"><b>DIANE PERES</b></p>
                    <p style="margin: unset">(66) 99687-8442</p>
                </td>
            </tr>
        </table>

        <table style="width: 100%; margin-top: 10px">
            <tr>
                <td>
                    <table >
                        <tr>
                            <td colspan="4">
                                <img style="" src="https://drive.google.com/a/jfassessoriarural.com.br/uc?id=1RoaS6NvraZ1Tt7Hfwx3Px-ygYCFigiIq&amp;export=download" width="60" height="60">
                            </td>
                            <td>
                                <p style="color:rgb(0,128,0); margin: unset; font-size: 20px"><b>JF Assessoria Rural Ltda.</b></p>
                                <p style="margin: unset; font-size: 12px">Rua das Seringueiras, 2552 - Sinop/MT</p>
                                <p style="margin: unset; font-size: 12px">(66) 3531-4164</p>
                            </td>
                        </tr>
                    </table>
                </td>
                <td style="text-align: right">
                    <p style="margin: unset; font-size: 12px">www.jfassessoriarural.com.br</p>
                    <p style="margin: unset; font-size: 12px">atendimento@jfassessoriarural.com.br</p>
                </td>
            </tr>
        </table>
    </div>

</template>

<script>
export default {
    name: "EmailFooter1"
}
</script>

<style scoped>

</style>