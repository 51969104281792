import gql from 'graphql-tag';

export class FiscalizacaoQueries {

    public static update = gql`mutation ($id:Int!, $fiscalizacao:FiscalizacaoInput!){
        updateFiscalizacao(id: $id, data: $fiscalizacao){
            id
        }
    }`;

    public static getTipos = gql`query {
        tipos{
            id
            nome
        }
    }`;

    public static getItensByTipo = gql`query($tipo: TipoInput!) {
        itensByTipo(tipo: $tipo){
            itemAFiscalizar{
                id
                descricao
            }
            valorPadrao
            posicaoPadrao
        }
    }`;

    public static minhasFiscalizacoesPendentes = gql`query {
        fiscalizacoes:minhasFiscalizacoesPendentes{
            id
            numeroSolicitacao
            dataLimite
            dataFiscalizacao
            emailEnviadoAt
            fotos{
                id
            }
            arquivos{
                id
            }
            contatos{
                id
            }
            financiamento{
                operacao
                operacaoDigito
                cedula
                mutuario
            }
        }
    }`;

    public static search = gql`query ($filter: SearchFilterInput!, $page: Int, $offset: Int){
        searchFiscalizacoes(filter: $filter, page: $page, offset: $offset){
            totalCount
            fiscalizacoes:itens{
                id
                numeroSolicitacao
                status
                dataLimite
                dataArquivoBanco
                dataFiscalizacao
                emailEnviadoAt
                regular
                tipo{
                    nome
                }
                fotos{
                    id
                }
                arquivos{
                    id
                }
                contatos{
                    id
                }
                lembretes{
                    id
                }
                financiamento{
                    operacao
                    operacaoDigito
                    cedula
                    mutuario
                    finalidade{
                        descricao
                    }
                }
                dataCancelamento
            }
        }
    }`;

    public static getById = gql`query($id: Int!) {
        fiscalizacao(id: $id){
            id
            status
            numeroSolicitacao
            tipo{
                id
                nome
            }
            emailEnviadoAt
            dataLimite
            dataFiscalizacao
            dataArquivoBanco
            distanciaPercorrida
            dataFinalizacao
            comentario
            dataFinalizacao
            distanciaPercorrida
            regular
            caminhoPolyline
            envioBancoSis
            dataCancelamento
            motivoCancelamento
            canceladoPor {
                id
                nome
                imagem {
                    id
                }
            }
            pontoPartida{
                cidade{
                    id
                    nome
                    estado{
                        nome
                        sigla
                    }
                }
            }
            supervisedBy{
                id
                nome
                sobrenome
                email
                imagem{
                    id
                }
            }
            fotos{
                id
            }
            arquivos{
                id
                nome
                createdAt
                locked
                tags{
                    id
                    nome
                }
            }
            contatos{
                id
                numero
                observacao
                whatsapp
            }
            lembretes{
                id
                descricao
                criadoPor{
                    id
                    nome
                    sobrenome
                    imagem{
                        id
                    }
                }
                createdAt
            }
            servico{
                id
                notaFiscal{
                    id
                }   
            }
            emailHistorico{
                id
                gmailEmailId
                gmailThreadId
                createdAt
            }
            solicitacoesProrrogacao{
                id
                justificativa
                dataLimiteAnterior
                dataLimiteSolicitada
                dataLimiteDefinida
                emailSolicitacao{
                    enviadoPor{
                        id
                        imagem{
                            id
                        }
                        nome
                        sobrenome
                    }
                }
                createdAt
            }
            fiscalizacoesMesmoMutuario {
                id
                numeroSolicitacao
                dataLimite
                dataFiscalizacao
                regular
                financiamento {
                    mutuario
                    operacao
                    operacaoDigito
                }
            }
            financiamento{
                id
                operacao
                operacaoDigito
                cedula
                dataCedula
                dataVencimento
                valor
                valorRecursosProprios
                valorTotalGarantias
                mutuario
                localGarantias
                finalidade{
                    id
                    codigo
                    descricao
                }
                empreendimento{
                    id
                    codigo
                    descricao
                }
                origemRecursos{
                    id
                    codigo
                    descricao
                }
                agencia{
                    id
                    nome
                    codigo
                    fechadaEm
                }
                area{
                    nome
                    coordenadas
                    roteiroDeAcesso
                    localizacao{
                        cidade{
                            id
                            codigo
                            nome
                            estado{
                                id
                                nome
                                sigla
                            }
                        }
                    }
                }
                objetos{
                    id
                    descricao{
                        id
                        codigo
                        descricao
                    }
                    qualificacao{
                        id
                        codigo
                        descricao
                    }
                    quantidade
                    quantidadeUnidadeMedida{
                        id
                        sigla
                    }
                    valor
                }
                garantias{
                    id
                    descricao{
                        id
                        codigo
                        descricao
                    }
                    qualificacao{
                        id
                        codigo
                        descricao
                    }
                    quantidade
                    quantidadeUnidadeMedida{
                        id
                        sigla
                    }
                    valor
                }
            }
        }
    }`;

    public static finalizarFiscalizacao = gql`mutation ($id:Int!, $dataFiscalizacao:LocalDate!, $distancia:Float!, 
                                                        $informante:String!, $funcaoInformante:String!, 
                                                        $itens:[FiscalizacaoItemInput]!, $comentario:String!,
                                                        $caminho: String, $is_regular:Boolean!, $incluirTodasCoordenadasNoLaudo:Boolean!){
        finalizarFiscalizacao(
            id: $id, 
            dataFiscalizacao: $dataFiscalizacao, 
            distancia: $distancia,
            informante: $informante, 
            funcao_informante: $funcaoInformante,
            itens: $itens, 
            comentario: $comentario, 
            caminho: $caminho,
            is_regular: $is_regular, 
            incluirTodasCoordenadasNoLaudo: $incluirTodasCoordenadasNoLaudo)
    }`;

    public static atribuirFiscalizacoes = gql`mutation ($fiscalizacoes: [FiscalizacaoInput]!){
        atribuirFiscalizacoes(fiscalizacoes: $fiscalizacoes)
    }`;

    public static deleteFoto = gql`mutation ($id: Int!){
        deleteFiscalizacaoFoto(id: $id)
    }`;

    public static deleteArquivo = gql`mutation ($id: Int!){
        deleteFiscalizacaoArquivo(id: $id)
    }`;

    public static enviarEmailAgencia = gql`mutation ($id: Int!, $dataLimite: LocalDate!){
        emailHistorico:enviaEmailParaAgencia(id: $id, dataLimite: $dataLimite){
            id
            createdAt
        }
    }`

    public static getContatosByFiscalizacaoId = gql`query ($id: Int!){
        contatosByFiscalizacao(id: $id){
            id
            numero
            observacao
            whatsapp
        }
    }`

    public static addContatoToFiscalizacao = gql`mutation ($contato:FiscalizacaoContatoInput!){
        addContatoToFiscalizacao(contato: $contato){
            id,
            numero
            observacao
            whatsapp
            fiscalizacao{
                id
            }
        }
    }`

    public static editContato = gql`mutation ($id:Int!, $contato:FiscalizacaoContatoInput!){
        editContato(id: $id, contato: $contato){
            id,
            numero
            observacao
            whatsapp
            fiscalizacao{
                id
            }
        }
    }`

    public static removeContato = gql`mutation ($id:Int!){
        removeContato(id: $id)
    }`
}
