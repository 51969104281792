var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Prezado Gerente.")]),_c('p',[_vm._v("Atendendo ao pedido do departamento 4510/072 – ON (Operações de Negócios), solicitamos a documentação para fiscalização do cliente abaixo que pertence a sua agência.")]),_vm._m(0),_c('br'),_c('table',[_c('tbody',[_vm._m(1),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.fiscalizacao.financiamento.cedula))]),_c('td',[_vm._v(_vm._s(_vm.fiscalizacao.financiamento.mutuario))])])])]),_c('br'),_vm._m(2),_c('ul',[_c('li',[_vm._v("Telefone do cliente;")]),(_vm.bndes)?_c('li',[_vm._v("Nota fiscal - de todos os itens comprados (obrigatório em BNDES);")]):_vm._e(),(_vm.bndes)?_c('li',[_vm._v("Cédula/Contrato.")]):_vm._e(),(_vm.incluirNF)?_c('li',[_vm._v("Nota fiscal - Em caso de compra de gado;")]):_vm._e(),(_vm.incluirNF2)?_c('li',[_vm._v("Nota fiscal - De todos os itens comprados;")]):_vm._e(),(_vm.incluirGTA)?_c('li',[_vm._v("GTA (Guia de Trânsito Animal);")]):_vm._e(),(_vm.incluirFichaSanitaria)?_c('li',[_vm._v("Ficha sanitária rural (saldo de gado). Obrigatória em custeio manutenção.")]):_vm._e()]),_c('br'),_c('p',[_vm._v("Enviar os documentos solicitados acima até "),_c('strong',[_vm._v(_vm._s(_vm._f("moment")(_vm.dataLimite,'DD [de] MMMM [de] YYYY')))]),_vm._v(" para fiscalizacao@jfassessoriarural.com.br.")]),_c('br'),_vm._m(3),_c('br'),_c('table',{staticStyle:{"margin-bottom":"20px"}},[_c('tbody',[_c('tr',[_c('td',[_c('strong',[_vm._v(_vm._s(_vm.fiscalizacao.supervisedBy.nome + ' ' + _vm.fiscalizacao.supervisedBy.sobrenome))]),_c('br'),_vm._m(4),_vm._v(" | "),_vm._m(5),_c('br'),_vm._m(6)])])])]),_c('email-footer1')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("A JF Assessoria Rural faz parte das empresas parceiras do Bradesco. Vide "),_c('b',[_vm._v("Crédito Rural e Desconto de NPR - Ano Agrícola 2021/2022. Número 03/197. Item 20.2. Projetos e Avaliações")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('strong',[_vm._v("CÉDULA  ")])]),_c('td',[_c('strong',[_vm._v("MUTUARIO")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Documentação necessária:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#ee6060","color":"white","padding":"6px 10px 10px","border":"1px solid #c20000","border-radius":"5px","text-align":"center","margin":"0 20px"}},[_c('p',{staticStyle:{"margin":"unset"}},[_c('b',[_vm._v("Atenção!")])]),_c('p',{staticStyle:{"margin":"unset"}},[_vm._v("O não cumprimento da exigência até a data limite deste e-mail ou a ausência de resposta acarretará em "),_c('b',[_vm._v("fiscalização irregular")]),_vm._v(". Caso tenha ocorrido algum imprevisto pela não apresentação dos documentos, explicar o motivo imediatamente respondendo este e-mail para que comuniquemos a central.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_vm._v("(66)99687-8442 "),_c('img',{attrs:{"src":"https://drive.google.com/uc?export=view&id=17sQbOpYa7LgsGzkxDbe2yLkC4XG0O4Z4","width":"14","height":"14"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_vm._v("(66)3531-4164 "),_c('img',{attrs:{"src":"https://drive.google.com/uc?export=view&id=17sQbOpYa7LgsGzkxDbe2yLkC4XG0O4Z4","width":"14","height":"14"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"mailto:fiscalizacao@jfassessoriarural.com.br","target":"_blank"}},[_vm._v("fiscalizacao@jfassessoriarural.com.br")])])
}]

export { render, staticRenderFns }